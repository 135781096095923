import React from 'react'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactForm from '@widgets/ContactForm'

export default props => (
  <Layout {...props}>
    <Seo title='Contact' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header="Write to us at admin@testcult.com"
          subheader='We would love to hear from you, be it a suggestion for new content,
                     feedback for improvement or for a cool collaboration, we are all ears!!
                     '
        />
        <Divider />
        {/*<ContactForm />*/}
      </Main>
    </Stack>
  </Layout>
)
